<template>
  <planification v-if="isLogin"/>
</template>

<script>
  import { mapState } from 'vuex';
  import planification from '@/components/planification/Planification';
  

  export default {
    name: 'Home',

    components: {
      planification,
    },

    data() {
      return {
        config: null
      };
    },

    computed: {
      ...mapState('auth', ['isLogin', 'user']),
    },
  }
</script>
