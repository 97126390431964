const config = {
  backend: '',
  cgu: '',
  promise: new Promise((resolve) => { 
    fetch('env.json').then(response => {
      return response.json();
    }).then(data => {
      config.backend = data.backend;
      config.cgu = data.cgu;
      resolve();
    })
  })
};

export default config;
