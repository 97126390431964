import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Planification from '@/components/planification/Planification';
import Commande from '@/components/commande/Commande';
import Documents from '@/components/documents/Documents';
import BonTransport from '@/components/bonTransport/BonTransport';
import AppUnauthorized from '@/components/common/AppUnauthorized';
import store from '@/store';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/planification',
    name: 'planification',
    component: Planification
  },
  {
    path: '/commande',
    name: 'commande',
    component: Commande
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/bontransport',
    name: 'bontransport',
    component: BonTransport
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: AppUnauthorized,
    meta: { title: 'Service inaccessible' }
  },
  {
    path: '*',
    redirect: '/'
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  
  if (to.name === 'unauthorized') {
    next();
  }
  else if(store.getters['auth/isLogin']) {
    if (!store.getters['data/currentCommande'].id && to.name !== 'planification' && to.name !== 'unauthorized') {
      next('/planification');
    }
  }

  next();
});


export default router;
