import Vue from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import toast from '@/plugins/toast';
import vueMobileDetection from 'vue-mobile-detection';
import env from '@/config/env';
import Keycloak from 'keycloak-js';
import 'whatwg-fetch';

Vue.use(vueMobileDetection);
Vue.use(toast, {});

Vue.config.productionTip = false;

import keycloakSrv from './services/keycloakService';

env.promise.then(async () => {
  let keycloakOptions = await keycloakSrv.getKeycloakInfos();

  let keycloak = new Keycloak(keycloakOptions);

  keycloakSrv
    .login(keycloak)
    .then(() => {
      /**
       * Injection des dépendances
       * "router" : donne accès à this.$router et this.$route dans chaque composant
       */
      window.Vue = new Vue({
        store,
        router,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    })
    .catch((errorMessage) => {
      console.error(errorMessage);
    });
});
