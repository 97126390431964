<template>
  <v-menu v-model="menuDate" :close-on-content-click="false" min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :label="label"
        :value="cDateFr"
        prepend-inner-icon="fas fa-calendar-alt"
        readonly
        outlined
        v-on="on"
        @click:clear="clearDate()"
      />
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      locale="fr-FR"
      :min="minDate"
      no-title
      @input="menuDate = false"
    />
  </v-menu>
</template>

<script>
import { format } from 'date-fns';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: false,
    },
    minDate: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      date: this.initialDate,
      menuDate: false,
    };
  },

  computed: {
    /**
     * Retourne la date au format DD/MM/YYYY
     */
    cDateFr() {
      let res = '';
      if (this.date) {
        this.$emit('dateChange', this.date);
        res = format(new Date(this.date), 'dd/MM/yyyy');
      }

      return res;
    },
  },

  watch: {
    initialDate(val) {
      this.date = val;
    },
  },

  methods: {
    clearDate() {
      this.date = '';
      this.$emit('dateChange', this.date);
    },
  },
};
</script>
