import AppToast from '@/components/common/AppToast';
import Vuetify from 'vuetify/lib';

const Toast = {
  install(Vue) {
    const AppToastConstructor = Vue.extend(AppToast);
    const instance = new AppToastConstructor();
    instance.$vuetify = new Vuetify().framework;
    let vm = instance.$mount();
    document.querySelector('body').appendChild(vm.$el);

    Vue.prototype.$toast = {
      info: instance.info,
      error: instance.error,
      warning: instance.warning,
      success: instance.success
    };
  }
};

export default Toast;
