<template>
  <v-container grid-list-md>
    <v-card elevation="0">
      <v-card-title
        :class="!$vuetify.breakpoint.md && !$vuetify.breakpoint.lg ? 'page-title' : 'page-title-lg'"
      >
        <span class="align-title">Bon de transport</span>
      </v-card-title>
      <v-card-text>
        <v-row class="row-margin">
          <v-col cols="12" sm="12" lg="6" md="6">
            <v-btn class="btn-tran" color="primary" block @click="activeChronopostDialog = true">
              <v-icon class="btn-icon-left"> fas fa-shipping-fast </v-icon>
              <span
                >Enlèvement sur demande <br />
                auprès de Chronopost
              </span>
            </v-btn>
          </v-col>
          <v-col class="center-text" cols="12" sm="12" lg="6" md="6">
            <v-btn class="btn-tran" color="accent" block @click="getEtiquette()">
              <v-icon class="btn-icon-left"> fas fa-cube </v-icon>
              <span
                >Dépôt en bureau de Poste ou <br />
                en relai colis Chronopost <br />
              </span>
            </v-btn>
            <span class="btn-devis">(frais applicables c.f devis)</span>
          </v-col>
          <v-col cols="12" sm="12" lg="12" md="12"> </v-col>
        </v-row>
        <v-row v-if="isLoad" class="row-margin">
          <v-col cols="12" sm="12" lg="12" md="12">
            <span class="msg">
              Le document est bien chargé
              <v-icon size="16">fas fa-check</v-icon>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="big-screen">
        <v-row class="row-margin-action">
          <v-col cols="0" sm="0" lg="9" md="8"> </v-col>
          <v-col cols="12" sm="12" lg="3" md="4" class="col-margin">
            <v-btn color="primary" block @click="newCommande()">
              <v-icon class="btn-icon-left"> fas fa-cart-plus </v-icon>
              <span> Terminer </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-footer class="small-screen" fixed color="white">
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" block @click="newCommande()">
            <v-icon class="btn-icon-left"> fas fa-cart-plus </v-icon>
            <span> Terminer </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <chronopost-dialog
      :active="activeChronopostDialog"
      :is-to-confirm="true"
      @confirmDialog="confirmDialog"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ChronopostDialog from '@/components/bonTransport/ChronopostDialog.vue';
import searchSrv from '@/services/searchService';
import mixins from '@/mixins/mixins.js';
import errorMixin from '@/mixins/errorMixin';

export default {
  name: 'BonTransport',
  components: {
    ChronopostDialog,
  },
  mixins: [errorMixin, mixins],
  data() {
    return {
      etiquette: null,
      commande: {},
      tiers: {},
      isLoad: false,
      activeChronopostDialog: false,
    };
  },

  computed: {
    ...mapState('data', ['currentCommande', 'currentTiers']),
    ...mapState('auth', ['user']),
  },

  watch: {
    currentCommande: {
      deep: true,
      handler(newVal) {
        this.commande = newVal;
      },
    },

    currentTiers: {
      deep: true,
      handler(newVal) {
        this.tiers = newVal;
      },
    },
  },

  created() {
    let cmd = this.currentCommande;
    if (cmd) {
      this.commande = cmd;
    }
    let ctiers = this.currentTiers;
    if (ctiers) {
      this.tiers = ctiers;
    }
  },

  methods: {
    ...mapMutations('loading', ['loading']),
    ...mapActions('data', ['removeCurrentCommande', 'removeCurrentTiers']),

    previous() {
      this.$router.push('/documents');
    },

    resetCommande() {
      this.removeCurrentTiers();
      this.removeCurrentCommande();
    },

    newCommande() {
      this.resetCommande();
      let these = this;
      setTimeout(function () {
        these.$router.push('/planification');
      }, 1000);
    },

    async getDoc() {
      this.isLoad = false;
      const url = document.getElementById('url');
      if (url) {
        url.click();
        this.isLoad = true;
      }
    },

    async getEtiquette() {
      if (!this.tiers || !this.currentCommande.id) {
        return;
      }

      this.loading(true);

      try {
        const etiquetteChronopost = await searchSrv.getEtiquette(
          this.tiers.tiers_code,
          this.commande.id,
        );
        this.toPdf(etiquetteChronopost.pdf, 'chronopost-' + this.commande.commande_id);
        this.loading(false);
        this.isLoad = true;
      } catch (error) {
        this.loading(false);
        this.handleServiceError(error);
      }
    },

    async confirmDialog(isConfirm, chronopostESDUrl) {
      this.activeChronopostDialog = false;
      if (!isConfirm || !chronopostESDUrl) {
        return;
      }

      this.loading(true);

      // appeler pour vider les éléments de la commande et du tiers sélectionné
      this.resetCommande();
      // redirection vers le site de Chronopost avec remplissage d'un certain nombre d'informations du tiers
      location.href = chronopostESDUrl;
    },
  },
};
</script>

<style scoped>
.small-screen {
  display: none;
}
.big-screen {
  display: block;
}
@media screen and (min-width: 959px) {
  .small-screen {
    display: none;
  }
  .big-screen {
    display: block;
  }
}
@media screen and (min-width: 0px) and (max-width: 959px) {
  .small-screen {
    display: block !important;
  }
  .big-screen {
    display: none !important;
  }
}
#url {
  display: none;
}
.row-margin {
  margin: -24px !important;
}
.row-margin-action {
  margin: -16px -16px -16px -16px !important;
}
.col-margin {
  margin-bottom: -10px;
}
.align-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.page-title {
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: -10px;
}
.page-title-lg {
  text-align: left;
  font-weight: 800;
  font-size: x-large;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: -10px;
}
.label-text {
  padding: 15px 5px 5px 0px;
  vertical-align: sub;
  font-size: larger;
}
.btn-text-left {
  margin-left: -10px;
}
.btn-icon-left {
  position: absolute;
  left: -10px;
}
.btn-icon-right {
  position: absolute;
  right: -10px;
}
.btn-tran {
  height: 60px !important;
}
.center-text {
  text-align: center !important;
}
.btn-devis {
  font-size: smaller;
}
.msg {
  font-size: 20px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
</style>
