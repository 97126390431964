import lockr from 'lockr';

export default {
  namespaced: true,
  state: {
    status: '',
    user: lockr.get('precap-user') || {},
    isLogin: lockr.get('precap-user') ? true : false
  },

  getters: {
    isLogin: (state) => state.isLogin,
    status: (state) => state.status,
    user: (state) => state.user
  },

  // Setters.
  mutations: {
    LOGIN: (state, user) => {
      lockr.set('precap-user', user);
      state.user = user;
      state.isLogin = true;
    },
    LOGOUT: state => {
      lockr.rm('precap-user');
      lockr.rm('precap-tiers');
      lockr.rm('precap-commande');
      state.user = {};
      state.isLogin = false;
    },
    STATUS: (state, value) => {
      state.status = value;
    },
    SWITCHLOGIN: (state, value) => {
      state.isLogin = value;
    },
    SWITCHTIERS: (state, user) => {
      lockr.set('precap-user', user);
      state.user = user;
    },
  },

  /**
   * Toute action asynchrone doit être dans actions
   */
  actions: {
    login({ commit }, data) {
      commit('LOGIN', data);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    status({ commit }, value) {
      commit('STATUS', value);
    },
    switchLogin({ commit }, value) {
      commit('SWITCHLOGIN', value);
    },
    switchTiers({ commit }, data) {
      commit('SWITCHTIERS', data);
    }
  }
};
