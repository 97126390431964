<template>
  <v-container grid-list-md>
    <v-card
     elevation="0"
    >
      <v-card-title
       :class="!$vuetify.breakpoint.md && !$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl ? 'page-title' : 'page-title-lg'"
      >
        <span class="align-title">Votre commande</span>
      </v-card-title>
      <v-card-text>
        <v-row class="row-margin">
          <v-col
            cols="12"
            sm="12"
            lg="5"
            md="5"
            :class="!$vuetify.breakpoint.md && !$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl ? 'align-text' : ''"
          >
            <span class="label-text-center label-text">
              Le numéro de commande qui vous est attribué est le
            </span>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="2"
            md="2"
            class="align-text"
          >
            <span class="cmd-id"> {{ commande.id }} </span>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="5"
            md="5"
            class="align-text"
          />
        </v-row>
        <v-row class="row-margin">
          <v-col
            cols="12"
            sm="12"
            lg="12"
            md="12"
            :class="!$vuetify.breakpoint.md && !$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl ? 'align-text' : ''"
          >
            <span class="label-text-center label-text">
              <strong>Ce numéro doit être impérativement noté sur l'exemplaire</strong> du bon de commande qui accompagne les échantillons.<br/>
              Merci de le reporter de façon bien visible en haut à droite de la feuille.<br/>
              Des exemples de bons de commande sont disponibles sous <a href="http://www.capinov.fr/feuille-de-demande-d-analyse.php" target="_blank">www.capinov.fr/feuille-de-demande-d-analyse.php</a>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="big-screen"
      >
        <v-row class="row-margin-action">
          <v-col
            cols="0"
            xs="0"
            sm="0"
            lg="8"
            md="6"
          />
          <v-col
            cols="6"
            xs="6"
            sm="6"
            lg="2"
            md="3"
          >
            <v-btn
              color="secondary"
              block
              @click="previous()">
                <v-icon class="btn-icon-left">
                  fas fa-arrow-alt-circle-left
                </v-icon>
                <span> Retour </span>
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            xs="6"
            sm="6"
            lg="2"
            md="3"
          >
            <v-btn
              color="primary"
              block
              @click="next()">
                <span> Suivant </span>
                <v-icon class="btn-icon-right">
                  fas fa-arrow-alt-circle-right
                </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-footer
      fixed
      color="white"
      class='small-screen'
    >
    <v-row class='footer-row'>
        <v-col
          cols="0"
          xs="0"
          sm="0"
          lg="8"
          md="6"
        />
        <v-col
          cols="6"
          xs="6"
          sm="6"
          lg="2"
          md="3"
        >
          <v-btn
            color="secondary"
            block
            @click="previous()">
              <v-icon class="btn-icon-left">
                fas fa-arrow-alt-circle-left
              </v-icon>
              <span> Retour </span>
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          xs="6"
          sm="6"
          lg="2"
          md="3"
        >
          <v-btn
            color="primary"
            block
            @click="next()">
              <span> Suivant </span>
              <v-icon class="btn-icon-right">
                fas fa-arrow-alt-circle-right
              </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Commande',
  data() {
    return {
      commande: {
        id: ''
      }
    };
  },

  created() {
    if(this.currentCommande) {
      this.commande = this.currentCommande;
    }
  },

  watch: {
    currentCommande: { 
      deep: true,
      handler (newVal) {
        this.commande = newVal;
      }
    },

    currentTiers: {
      deep: true,
      handler (newVal) {
        this.tiers = newVal;
      }
    }
  },

  computed: {
    ...mapState('data', ['currentCommande']),
  },

  methods: {
    // Map l'action "login" du store vers "this.store_login".
    previous() {
      this.$router.push('/planification');
    },

    next() {
      this.$router.push('/documents');
    },
  },
};
</script>

<style scoped>
.small-screen {
  display: none;
}
.big-screen {
  display: block;
}
@media screen and (min-width: 959px) {
  .cmd-id {
    font-weight: 800;
    font-size: x-large !important;
  }
  .small-screen {
    display: none;
  }
  .big-screen {
    display: block;
  }
}
@media screen and (min-width: 0px) and (max-width: 959px) {
  .small-screen {
    display: block !important;
  }
  .big-screen {
    display: none !important;
  }
}
@media screen and (min-width: 960px) and (max-width: 1263px) {
  .cmd-id {
    font-weight: 800;
    font-size: large !important;
  }
}
.label-text{
  padding: 15px 5px 5px 0px;
  vertical-align: sub;
  font-size: larger;
}
.label-text-center {
  padding: 15px 5px 5px 0px;
  text-align: center;
  font-size: larger;
}
.row-margin {
  margin: -24px !important;
}
.row-margin-action  {
  margin: 40px -16px -16px -16px !important;
}
.align-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.page-title {
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: -10px;
}
.page-title-lg {
  text-align: left;
  font-weight: 800;
  font-size: x-large;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: -10px;
}
.align-text {
  text-align: center;
  padding-top: 35px !important;
  padding-bottom: 35px  !important;
}
.btn-text-left {
  margin-left: -10px;
}
.btn-icon-left {
  position: absolute;
  left: -10px;
}
.btn-icon-right {
  position: absolute;
  right: -10px;
}
.cmd-id {
  font-weight: 800;
  font-size: xx-large;
}
.footer-tag {
  height: 50px !important;
}
.footer-row {
  margin: -40px -12px 12px -12px !important;
}
</style>