<template>
  <v-container grid-list-md>
    <v-card elevation="0">
      <v-card-title
        :class="!$vuetify.breakpoint.md && !$vuetify.breakpoint.lg ? 'page-title' : 'page-title-lg'"
      >
        <span class="align-title">Chargement du formulaire de demande</span>
      </v-card-title>
      <v-card-text>
        <v-row class="row-margin">
          <v-col cols="12" sm="12" lg="12" md="12">
            <div class="dropbox" @click="uploadFile" @drop.prevent="addFile" @dragover.prevent>
              <input
                type="file"
                @change="filesChange($event.target.name, $event.target.files)"
                accept=".pdf,.jpeg,.jpg"
                class="input-file"
                id="input-file"
              />
              <v-icon class="drag-icon"> fas fa-plus </v-icon>
              <p class="drag-text">
                Déposer votre formulaire pdf/jpg ici
                <br v-show="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" />
                ou cliquer pour le charger depuis votre
                <span class="phone">smartphone</span>
                <span class="pc">ordinateur</span>
                <br />
                La taille de vos documents doit être inférieure à 8 Mo et comporter 10 pages
                maximum.
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="12" lg="6" md="6" class="small-screen">
            <v-btn class="btn-picture" color="accent" block @click="takePicture()">
              <v-icon class="btn-icon-left"> fas fa-camera </v-icon>
              <span> Prendre une photo</span>
              <input
                id="camera"
                type="file"
                accept="image/jpeg"
                capture="camera"
                value="Prendre une photo"
                @change="setPicture"
              />
            </v-btn>
            <span class="text-info"> La photo doit-être en vertical et bien cadrée </span>
          </v-col>
          <v-col cols="12" sm="12" lg="12" md="12">
            <v-list thow-line :class="files.length ? 'zone-doc scroll' : 'zone-doc'">
              <template v-for="(file, index) in files">
                <v-list-item
                  :class="index === files.length - 1 ? 'last-item-list' : 'item-list'"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-icon v-if="file && file.type && file.type.includes('pdf')" color="red">
                      fas fa-file-pdf
                    </v-icon>
                    <v-icon v-if="file && file.type && file.type.includes('image')" color="blue">
                      fas fa-file-image
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="file.name" />
                    <v-list-item-subtitle v-if="file.size">
                      {{ file.size }} Mo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="file.transferred" color="primary" size="16">
                      fas fa-check
                    </v-icon>
                    <v-btn data-test="btn-trash" v-if="!documentsLocked" icon @click="deleteFile(index)">
                      <v-icon color="red" size="16"> fas fa-trash-alt </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="big-screen">
        <v-row class="row-margin-action">
          <v-col cols="0" sm="0" lg="6" md="4" class="col-margin" />
          <v-col cols="12" sm="12" lg="2" md="3" class="col-margin">
            <v-btn color="secondary" block @click="previous()">
              <v-icon class="btn-icon-left"> fas fa-arrow-alt-circle-left </v-icon>
              <span> Retour </span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="5" class="col-margin">
            <v-btn :disabled="!files.length" color="primary" block @click="next()">
              <span class="btn-text-left"> Transmettre à Capinov </span>
              <v-icon class="btn-icon-right"> fas fa-share </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-footer class="small-screen" fixed color="white">
      <v-row>
        <v-col cols="12" sm="12" class="col-margin">
          <v-btn color="secondary" block @click="previous()">
            <v-icon class="btn-icon-left"> fas fa-arrow-alt-circle-left </v-icon>
            <span> Retour </span>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <v-btn data-test="btn-transmettre" :disabled="!files.length" color="primary" block @click="next()">
            <span class="btn-text-left"> Transmettre à Capinov </span>
            <v-icon class="btn-icon-right"> fas fa-share </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import updateSrv from '../../services/updateService';
import mixins from '@/mixins/mixins.js';
import errorMixin from '@/mixins/errorMixin';

export default {
  name: 'documents',
  mixins: [errorMixin, mixins],
  data() {
    return {
      files: [],
      hasCamera: false,
      commande: {},
      tiers: {},
      msg: 'Les documents chargés ne seront pas enregistrées.',
      active: false,
      event: null,
      documentsLocked: false,
    };
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.reload);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.reload);
  },

  computed: {
    ...mapState('data', ['currentCommande', 'currentTiers']),
    ...mapState('auth', ['user']),
  },

  watch: {
    currentCommande: {
      deep: true,
      handler(newVal) {
        this.commande = newVal;
      },
    },

    currentTiers: {
      deep: true,
      handler(newVal) {
        this.tiers = newVal;
      },
    },
  },

  created() {
    this.isOk = false;
    let cmd = this.currentCommande;
    if (cmd) {
      this.commande = cmd;
    }
    let ctiers = this.currentTiers;
    if (ctiers) {
      this.tiers = ctiers;
    }
  },

  methods: {
    ...mapMutations('loading', ['loading']),
    ...mapMutations('data', ['setCurrentCommande']),

    previous() {
      this.$router.push('/commande');
    },

    async next() {
      await this.saveDocs();
    },

    actionOnServiceError(error) {
      if (error.message === '413') {
        this.$toast.warning('Votre document doit contenir 10 pages au maximum', -1);
      } else {
        this.handleServiceError(error);
      }
    },

    checkFile(file) {
      if (!file.type.includes('pdf') && !file.type.includes('image/jpeg')) {
        this.$toast.warning('Le fichier doit être de format pdf ou jpeg');
        return false;
      }
      if (file.size > 8000000) {
        this.$toast.warning('La taille de fichier doit être inférieur à 8 Mo');
        return false;
      }
      return true;
    },

    appendFile(afile) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        if (!this.checkFile(afile)) {
          return;
        }
        let file = {
          name: afile.name,
          type: afile.type,
          size: parseFloat(afile.size / 1000000).toFixed(2),
          content: fileReader.result,
          transferred: false,
        };
        this.files.push(file);
      });
      fileReader.readAsDataURL(afile);
    },

    filesChange(fieldName, files) {
      if (!files || !files.length) return;
      this.appendFile(files[0]);
    },

    addFile(e) {
      let files = e.dataTransfer.files;
      if (!files || !files.length) return;
      this.appendFile(files[0]);
    },

    deleteFile(index) {
      if (this.files && this.files[index]) {
        this.files.splice(index, 1);
      }
    },

    takePicture() {
      const camera = document.getElementById('camera');
      if (camera) {
        camera.click();
      }
    },

    uploadFile() {
      const inputFile = document.getElementById('input-file');
      if (inputFile) {
        inputFile.click();
      }
    },

    setPicture(event) {
      const files = event.target.files;
      if (!files || !files.length) return;
      this.appendFile(files[0]);
    },

    async saveDocs() {
      this.documentsLocked = true;
      let isOk = false;
      for (let i = 0; i < this.files.length; i++) {
        if (!this.files[i].transferred) {
          // Ne sauve que les fichiers non déjà transférés
          let doc = {
            document: this.files[i].content,
            type_document: this.files[i].type,
            dernier: i === this.files.length - 1,
          };
          let res = await this.saveDoc(doc, this.files[i]);
          if (!res) {
            i = this.files.length;
            isOk = false;
          } else {
            isOk = true;
          }
        } else {
          isOk = true;
        }
      }
      if (isOk) {
        this.$toast.success('Les documents sont transmis.');
        this.$router.push('/bontransport');
      }
    },

    async saveDoc(doc, file) {
      if (this.tiers && this.commande && (!this.tiers.tiers_code || !this.commande.id)) {
        return false;
      }

      this.loading(true);

      try {
        await updateSrv.saveDocument(this.tiers.tiers_code, this.commande.id, doc);
        this.loading(false);
        file.transferred = true;
        return true;
      } catch (error) {
        this.loading(false);
        this.actionOnServiceError(error);
        return false;
      }
    },
  },
};
</script>

<style scoped>
.phone {
  display: none;
}
.pc {
  display: none;
}
.small-screen {
  display: none;
}
.big-screen {
  display: block;
}
@media screen and (min-width: 959px) {
  .small-screen {
    display: none;
  }
  .big-screen {
    display: block !important;
  }
  .phone {
    display: none !important;
  }
  .pc {
    display: inline-block;
  }
}
@media screen and (min-width: 0px) and (max-width: 959px) {
  .small-screen {
    display: block !important;
  }
  .big-screen {
    display: none !important;
  }
  .phone {
    display: inline-block !important;
  }
  .pc {
    display: none !important;
  }
}
.zone-doc {
  height: 150px;
}
@media screen and (min-height: 506px) {
  .zone-doc {
    height: calc(200px);
  }
}
.row-margin {
  margin: -24px !important;
}
.row-margin-action {
  margin: -16px -16px -16px -16px !important;
}
.col-margin {
  margin-bottom: -10px;
}
.align-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.page-title {
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: -10px;
}
.page-title-lg {
  text-align: left;
  font-weight: 800;
  font-size: x-large;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: -10px;
}
.label-text {
  padding: 15px 5px 5px 0px;
  vertical-align: sub;
  font-size: larger;
}
.btn-text-left {
  margin-left: -10px;
}
.btn-icon-left {
  position: absolute;
  left: -10px;
}
.btn-icon-right {
  position: absolute;
  right: -10px;
}
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 150px; /* minimum height */
  cursor: pointer;
  text-align: center;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 45%;
  height: 130px;
  position: absolute;
  cursor: pointer;
  visibility: hidden;
}
.drag-text {
  margin-top: 10px;
}
.drag-icon {
  /* position: relative;
  left: 46%; */
  margin-top: 15px;
}
.text-info {
  font-size: 1em;
  color: red;
  display: block;
  text-align: center;
}
.bottom-pos {
  position: relative;
  bottom: 0;
}
.scroll {
  overflow-y: scroll;
}
.btn-picture input {
  display: none;
}
.item-list {
  height: 40px;
}
.last-item-list {
  height: 40px;
  margin-bottom: 40px;
}
</style>
