import env from '@/config/env';
import store from '@/store';

let appUrl;
/**
 * @type {import('keycloak-js').KeycloakInstance}
 */
let authKeycloak;

/**
 * Callback appelée une fois l'utilisateur correctement authentifié.
 * On peut alors aller initialiser les infos de l'utilisateur dans le store.
 */
let actionOnAuthSuccess = () => {
  let isLogin = store.getters['auth/isLogin'];

  if (authKeycloak && authKeycloak.tokenParsed && authKeycloak.token) {
    store.commit('auth/LOGIN', {
      login: '',
      name: '',
      email: authKeycloak.tokenParsed.preferred_username,
      token: authKeycloak.token,
    });
  }

  if (isLogin) {
    // l'utilisateur est connecté => on met à jour le token du store.
    let user = store.getters['auth/user'];
    user.token = authKeycloak.token;
  }
};

/**
 * Callback appelée à l'expiration de l'access token.
 * On peut alors récupérer l'access token mis à jour pour modifier le store.
 */
let actionOnTokenExpired = () => {
  authKeycloak
    .updateToken(40)
    .then((refreshed) => {
      if (refreshed) {
        let user = store.getters['auth/user'];
        user.token = authKeycloak.token;
        user.email = authKeycloak.tokenParsed.preferred_username;
        store.commit('auth/LOGIN', user);
      } else {
        console.log('Token still valid');
      }
    })
    .catch((e) => {
      console.log('Failed to refresh token');
      console.log(e);
    });
};

/**
 * Callback appelée une fois l'utilisateur est déconnecté.
 * On peut alors aller initialiser les infos de l'utilisateur dans le store.
 */
let actionOnAuthLogout = () => {
  store._mutations['auth/LOGOUT'];
};

/**
 * @returns {string}
 */
let getOrigin = () => {
  if (!window.location.origin) {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '')
    );
  } else {
    return window.location.origin;
  }
};

export default {
  /**
   * @returns {Object}
   */
  getKeycloakInfos: async () => {
    let loader = document.getElementById('loader');
    let err = document.getElementById('error');
    loader.style.display = 'block';
    // On récupère l'Url de keycloak.
    try {
      let resp = await fetch(`${env.backend}keycloak`, {});
      if (resp.ok) {
        let res = await resp.json();
        loader.style.display = 'none';
        if (res) {
          return res;
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      err.style.display = 'block';
      loader.style.display = 'none';
      console.error('Problème de connexion au serveur.');
      return;
    }
  },

  /**
   * @returns {Promise}
   */
  login: (pKeycloak) => {
    authKeycloak = pKeycloak;
    authKeycloak.onAuthSuccess = actionOnAuthSuccess;
    authKeycloak.onAuthLogout = actionOnAuthLogout;
    authKeycloak.onTokenExpired = actionOnTokenExpired;

    return authKeycloak
      .init({
        // Enables logging messages from Keycloak to the console.
        // enableLogging: true,
        // 'login-required' will authenticate the client if the user is logged-in to Keycloak or display the login page if not.
        // 'check-sso' will only authenticate the client if the user is already logged-in, if the user is not logged-in the browser will be redirected back to the application and remain unauthenticated.
        onLoad: 'login-required',
      })
      .then((auth) => {
        if (!auth) {
          window.location.reload();
        }

        if (authKeycloak.token) {
          if (!appUrl) {
            appUrl = getOrigin();
          }

          return Promise.resolve();
        } else {
          store.commit('auth/LOGOUT');
        }

        return Promise.reject('Authentication failure : token absent.');
      })
      .catch(() => {
        return Promise.reject("Erreur lors de la tentative d'authentification.");
      });
  },

  logout: () => {
    authKeycloak.logout({
      redirectUri: appUrl,
    });
  },
};
