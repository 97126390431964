export default {
   methods: {
      toPdf(data, filename) {
         var link = document.createElement('a');
         link.innerHTML = "Télecharger l'étiquette chronopost";
         link.download = filename+'.pdf';
         link.href = 'data:application/octet-stream;base64,' + data;
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
      },
      goToUrl(url, target){
         Object.assign(document.createElement('a'), {
            target: target ? target : '_blank',
            href: url,
         }).click();
      },
      reload(event) {
         event.preventDefault();
         event.returnValue = "";
      },
   },
};