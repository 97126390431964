<template>
  <v-app>
    <app-header v-show="isLogin" />
    <app-install />
    <v-main>
      <router-view />
    </v-main>
    <app-loader />
  </v-app>
</template>

<script>
import AppHeader from '@/components/common/AppHeader';
import AppInstall from '@/components/common/AppInstall';
import AppLoader from '@/components/common/AppLoader';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppInstall,
    AppLoader,
  },

  data: () => ({}),

  created() {
  },

  computed: {
    ...mapState('auth', ['isLogin']),
  },

  methods: {},
};
</script>
