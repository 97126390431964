export default {
  methods: {
    /**
     * Log l'erreur dans la console et déclenche un toast.error.
     * @param {Error} error
     */
    handleServiceError(error) {
      console.log('😒', error);
      let errorMessage;

      switch (error.message) {
        case '403':
          errorMessage = 'Accès refusé';
          this.$toast.warning(errorMessage, -1);
          this.$router.push('/unauthorized');
          break;

        case '404':
          errorMessage = 'Pas de résultat';
          this.$toast.warning(errorMessage, -1);
          break;

        case '504':
          errorMessage = 'Lenteur réseau : veuillez réessayer ultérieurement';
          this.$toast.warning(errorMessage, -1);
          break;

        default:
          errorMessage = 'Une erreur est survenue';
          this.$toast.error(errorMessage, -1);
          break;
      }
    },
  },
};
