import httpService from './httpService';

export default {
  saveCommande: async (tiers_code, commande) => {
    return httpService.post(
      `tiers/${tiers_code}/commandes`,
      JSON.stringify(commande)
    );
  },

  updateCommande: async (tiers_code, commande) => {
    return httpService.put(
      `tiers/${tiers_code}/commandes/${commande.id}`,
      JSON.stringify(commande)
    );
  },

  deleteCommande: async (tiers_code, commande) => {
    return httpService.delete(`tiers/${tiers_code}/commandes/${commande.id}`);
  },

  saveDocument: async (tiers_code, commandeId, document) => {
    return httpService.post(
      `tiers/${tiers_code}/commandes/${commandeId}/documents`,
      JSON.stringify(document)
    );
  }
};
