import lockr from 'lockr';
import { addDays, format } from 'date-fns';

export default {
  namespaced: true,
  state: {
    commandeStatus: {
      encours: 1,
    },
    currentCommande: lockr.get('precap-commande')
      ? lockr.get('precap-commande')
      : {
          id: '',
          echant_nombre: 1,
          date_creation: '',
          date_previs_reception: '',
          contrat_code: '',
          type_analyse: '',
          etat: 1,
        },
    currentTiers: lockr.get('precap-tiers') ? lockr.get('precap-tiers') : null,
    listTiers: [],
  },

  getters: {
    currentCommande: (state) => {
      let cmd = lockr.get('precap-commande');
      if (cmd) {
        return cmd;
      } else {
        return state.currentCommande;
      }
    },
    currentTiers: (state) => {
      let cmd = lockr.get('precap-tiers');
      if (cmd) {
        return cmd;
      } else {
        return state.currentTiers;
      }
    },
    listTiers: (state) => state.listTiers,
  },

  // Setters.
  mutations: {
    /**
     * Mise à jour de la commande
     * @param value
     */
    setCurrentCommande: (state, value) => {
      lockr.set('precap-commande', value);
      state.currentCommande = value;
    },
    /**
     * Mise à jour de tiers
     * @param value
     */
    setCurrentTiers: (state, value) => {
      state.currentTiers = value;
      lockr.set('precap-tiers', value);
    },
    /**
     * Mise à jour de la liste des commandes
     * @param value
     */
    setListTiers: (state, value) => {
      state.listTiers = value;
    },
    /**
     * suppression de la commande
     */
    removeCurrentCommande: (state) => {
      lockr.rm('precap-commande');
      state.currentCommande = {
        id: '',
        echant_nombre: 1,
        date_creation: format(new Date(), 'yyyy-MM-dd'),
        date_previs_reception: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        contrat_code: '',
        type_analyse: '',
        etat: 1,
      };
    },
    /**
     * suppression de tiers
     */
    removeCurrentTiers: (state) => {
      lockr.rm('precap-tiers');
      state.currentTiers = null;
    },
  },

  /**
   * Toute action asynchrone doit être dans actions
   */
  actions: {
    removeCurrentCommande({ commit }) {
      commit('removeCurrentCommande');
    },
    removeCurrentTiers({ commit }) {
      commit('removeCurrentTiers');
    },
  },
};
