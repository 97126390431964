<template>
  <div>
    <v-app-bar color="primary" dark fixed app height="50">
      <div class="logo">
        <img
          class="img"
          :src="require('@/assets/capinov-logo.png')"
          alt="logo"
        />
      </div>
      <div class="logo-precap">
        <h4>Gestion des commandes</h4>
      </div>
      <v-spacer />
      <v-app-bar-nav-icon
        v-if="isLogin && !isUnauthorized"
        class="s-menu"
        @click.stop="drawer = !drawer"
      />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed app temporary right>
      <v-toolbar flat height="48">
        <v-avatar size="48">
          <v-icon size="42" larger>
            fas fa-user-circle
          </v-icon>
        </v-avatar>
        <div class="user-lateral">
          <div class="user-name-lateral">
            {{ user.name }} {{ user.login ? `(${user.login})` : '' }}
          </div>
          <div class="user-info-lateral">
            {{ user.email }}
          </div>
        </div>
      </v-toolbar>
      <v-divider />
      <v-list-item-group>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>
              fas fa-sign-out-alt
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Déconnexion
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goToCGU()">
          <v-list-item-icon>
            <v-icon>
              fas fa-file-contract
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              CGUs
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <template v-slot:append>
        <div class="text-right">
          <small> (v{{ appVersion }})</small>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import keycloakSrv from '../../services/keycloakService';
import updateSrv from '../../services/updateService';
import mixins from '@/mixins/mixins.js';
import errorMixin from '@/mixins/errorMixin';
import env from '@/config/env';

export default {
  mixins: [errorMixin, mixins],
  data() {
    return {
      showCommande: false,
      drawer: false,
      isUnauthorized: false,
    };
  },

  computed: {
    ...mapState('auth', ['isLogin', 'user']),
    ...mapState('data', ['currentCommande', 'currentTiers']),
    ...mapGetters('common', ['appVersion']),
  },

  watch: {
    '$route.name': {
      deep: true,
      handler(newVal) {
        this.isUnauthorized = newVal === 'unauthorized';
      },
    },
  },

  created() {
    this.isUnauthorized = this.$route.name === 'unauthorized';
  },

  methods: {
    ...mapActions('auth', { storeLogout: 'logout' }),
    ...mapActions('data', ['removeCurrentCommande', 'removeCurrentTiers']),
    resetStore() {
      this.removeCurrentCommande();
      this.removeCurrentTiers();
    },

    closeMenu() {
      this.drawer = false;
    },

    goTo(path) {
      if (this.$route.name !== path) {
        this.$router.push(`/${path}`);
      }
      this.closeMenu();
    },

    logout() {
      this.closeMenu();
      //this.deleteCommande();
      this.storeLogout();
      this.resetStore();
      keycloakSrv.logout();
    },

    goToCGU() {
      this.goToUrl(env.cgu);
      this.closeMenu();
    },

    async deleteCommande() {
      if (!this.currentTiers || !this.currentCommande.id) {
        return;
      }
      if (this.currentCommande.id && this.currentCommande.etat === 1) {
        try {
          await updateSrv.deleteCommande(
            this.currentTiers.tiers_code,
            this.currentCommande,
          );
        } catch (error) {
          this.handleServiceError(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.logo {
  margin-left: -16px;
  margin-top: -1px;
  width: 93px;
  background-color: white;
  max-height: 48px;
}
.img {
  width: 95%;
  height: 100%;
}
.logo-precap {
  display: block;
  width: 100%;
  text-align: center;
}
.img-precap {
  margin: auto;
  display: block;
}
.user-lateral {
  display: inline-block;
  padding-left: 5px;
}
.user-name-lateral {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.user-info-lateral {
  display: block;
  font-size: 10px;
}
.v-list-item__icon:first-child {
  margin-right: 10px !important;
}
</style>
