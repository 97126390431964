<template>
    <v-banner
      v-if="deferredPrompt"
      color="info"
      dark
      class="text-left banner"
    >
      Ajouter gestion des commandes à l'écran d'accueil

      <template v-slot:actions>
        <v-btn text @click="dismiss">
          Annuler
        </v-btn>
        <v-btn text @click="install">
          Installer
        </v-btn>
      </template>
    </v-banner>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: 'AppInstall',
  data() {
    return {
      deferredPrompt: null,
    };
  },

  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },

  methods: {
    async dismiss() {
      Cookies.set("add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
};
</script>
<style>
.banner {
  position: absolute;
  top: 83px;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
}
@media (max-width: 1025px) {
  .banner {
    width: 100%;
  }
}
</style>
