<template>
  <v-snackbar
    v-model="active"
    :timeout="timeout"
    :color="color"
    :bottom="y === 'bottom'"
    :top="y === 'top'"
    :left="x === 'left'"
    :right="x === 'right'"
    :multi-line="multiLine"
    :vertical="vertical"
    class="application"
  >
    <v-icon v-if="!!icon" dark left>
      {{ icon }}
    </v-icon>
    &nbsp;{{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="dismiss">
        <v-icon v-if="dismissable" dark right class="cross">
          fas fa-times
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: 'AppToast',
  data() {
    return {
      active: false,
      color: '',
      dismissable: true,
      icon: '',
      message: '',
      multiLine: true,
      timeout: 3000,
      vertical: false,
      y: 'top',
      x: null,
    };
  },

  methods: {
    close() {
      this.active = false;
    },

    dismiss() {
      if (this.dismissable) {
        this.close();
      }
    },

    error(text, timeout = 3000) {
      this.color = '#d53d18';
      this.icon = 'far fa-thumbs-down';
      this.timeout = timeout;
      this.show(text);
    },

    info(text, timeout = 3000) {
      this.color = '#2196F3';
      this.icon = 'fas fa-info';
      this.timeout = timeout;
      this.show(text);
    },

    show(text = 'This is the default message') {
      this.message = text;
      this.active = true;
    },

    success(text, timeout = 3000) {
      this.color = '#7ea71d';
      this.icon = 'far fa-thumbs-up';
      this.timeout = timeout;
      this.show(text);
    },

    warning(text, timeout = 3000) {
      this.color = '#FFC107';
      this.icon = 'fas fa-exclamation';
      this.timeout = timeout;
      this.show(text);
    },
  },
};
</script>


<style scoped>
div {
  font-family: 'Roboto', sans-serif;
}
.cross {
  text-align: center;
}
.cross:hover {
  cursor: pointer;
  transform-origin: center center;
  transform: rotate(30deg) scale(1.3);
}
</style>
