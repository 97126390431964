<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="active" max-width="600px" persistent>
    <v-card>
      <v-toolbar color="primary" dark>{{ title }}</v-toolbar>
      <v-card-text>
        <div v-if="message" class="pa-2">
          {{ message }}
        </div>
        <div v-else class="pa-2">
          Vous allez être redirigé vers le site de Chronopost.<br />Vous devrez remplir le
          formulaire et renseigner le code authentification :
          <strong>capiboxesd</strong>
          <p class="mt-2">
            En cas de besoin, une prodécure est disponible
            <a :href="chronopostESDDoc" target="_blank">ici</a>
          </p>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!isToConfirm" color="green darken-1" text @click="closeDialog()"> Ok </v-btn>
        <v-btn v-if="isToConfirm" color="green darken-1" text @click="confirmDialog(false)">
          Annuler
        </v-btn>
        <v-btn v-if="isToConfirm" color="green darken-1" text @click="confirmDialog(true)">
          Confirmer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import searchSrv from '@/services/searchService';
import mixins from '@/mixins/mixins.js';
import errorMixin from '@/mixins/errorMixin';

export default {
  name: 'ChronopostDialog',
  mixins: [errorMixin, mixins],

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Attention !',
    },
    message: {
      type: String,
      default: '',
    },
    isToConfirm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chronopostESDDoc: null,
      chronopostESDUrl: null,
    }
  },

  computed: {
    ...mapState('data', ['currentCommande', 'currentTiers']),
  },

  created() {
    this.getChronopostInfos();
  },

  methods: {
    ...mapMutations('loading', ['loading']),

    closeDialog() {
      this.$emit('switchDialog', false);
    },

    confirmDialog(confirm) {
      this.$emit('confirmDialog', confirm, this.chronopostESDUrl);
    },

    async getChronopostInfos() {
      this.loading(true);

      try {
        const dataFromApi = await searchSrv.getChronopostInfos(this.currentTiers.tiers_code, this.currentCommande.id);

        this.chronopostESDDoc = dataFromApi.chronopost_doc_esd_url;
        this.chronopostESDUrl = new URL(dataFromApi.chronopost_form_esd_url).toString();
        this.loading(false);
      } catch (error) {
        this.loading(false);
        this.handleServiceError(error);
      }
    },
  },
};
</script>
