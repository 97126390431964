import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
// @see https://stackoverflow.com/questions/67615517/vuetify-difference-between-import-vuetify-lib-framework-vs-vuetify-lib
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#7ab737',
        secondary: '#8a8a8d',
        accent: '#2196F3',
        error: '#d53d18',
        info: '#2196F3',
        success: '#7ea71d',
        warning: '#FFC107',
        chronopost: '#009adf'
      }
    }
  }
});