import Vue from 'vue';
import Vuex from 'vuex';

import data from './modules/data';
import auth from './modules/auth';
import loading from './modules/loading';
import common from './modules/common';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    data,
    auth,
    loading,
    common,
  },
});
