import env from '@/config/env';

/**
 *
 * @param {string} url
 * @param {*} params
 * @returns
 */
async function httpRequest(url, params) {
  const STORE_NAME = 'precap-user';
  const URL_TO_FETCH = /^https?:\/\//i.test(url) ? url : env.backend + url;

  if (
    !localStorage.getItem(STORE_NAME) ||
    !JSON.parse(localStorage.getItem(STORE_NAME)).data ||
    !JSON.parse(localStorage.getItem(STORE_NAME)).data.token
  ) {
    throw new Error('Connexion perdue');
  }

  const mergedHeaders = { 'Authorization':  `Bearer ${JSON.parse(localStorage.getItem(STORE_NAME)).data.token}`, ...params.headers};
  const mergedParams = { ...params, ...{ headers: mergedHeaders } };

  const response = await fetch(URL_TO_FETCH, mergedParams);

  if (!response.ok) {
    throw new Error(response.status);
  }

  return response.json();
}

export default {
  delete(url, params = {}) {
    const mergedParams = { method: 'DELETE', ...params };
    return httpRequest(url, mergedParams);
  },

  get(url, params = {}) {
    const mergedParams = { method: 'GET', ...params };
    return httpRequest(url, mergedParams);
  },

  post(url, body, params = {}) {
    const headers = {
      'content-type': 'application/json',
    };
    const mergedParams = {
      method: 'POST',
      headers,
      body,
      ...params,
    };
    return httpRequest(url, mergedParams);
  },

  put(url, body, params = {}) {
    const headers = {
      'content-type': 'application/json',
    };
    const mergedParams = {
      method: 'PUT',
      headers,
      body,
      ...params,
    };
    return httpRequest(url, mergedParams);
  },
};
